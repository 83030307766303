<template>
    <header>
        
        <div class="container">
            <div class="logo">
                 <img src="../assets/alphadof_logo.png" alt="" height="45" >
            </div>
        </div>
        
        
       
    </header>
  
</template>


<style scoped>
header{
    /* padding-left: 2rem; */
    padding-top: 1rem;
    padding-bottom:1rem;
    /* margin-top: 0;  */
    /* z-index: 99; */
    /* background: rgba(21, 92, 220, 100); */
    
    
}
nav{
    position: relative;
    display: flex;
    flex-direction: row;
    padding: .25rem 0;
    transition: .5s ease all;
    margin: 0 auto;
    @media (min-width:1140px) {
        max-width:1140px;
    }
}

/* ul{
    list-style: none;
} */

.navigation{
    display: flex;
    flex: 1;
    position: relative;
    align-items: center; 
    justify-content: flex-end;    
    float: right;
}

.logo{
    /* display: flex; */
    height: 2rem;
    /* margin-top: 1rem; */
}

@media (max-width:40rem) {
    .logo{
    display: flex;
    height: 2rem;
    margin-top: 1rem;
    justify-content: center;
    
}
}
.heading{
    display: flex;
    justify-content: center;
    padding-top:1rem ;
    padding-left: .25rem;
}
@media only screen and (max-width:375px) {
    header{
       padding-left:0; 
    }

}

.split-header{
    display: flex;
    flex-direction: row;
    
}
.split-main{
    justify-content: center;
    align-self: flex-end;
    
    
}
.split{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
ul,


.icon{
    display: flex;
    align-items: center;
    margin-top: .25rem;
    /* top: 0; */
    width: 2rem;
    right: 60px;
    height: 100%;
    float: right;
    justify-content: flex-end; 
}
.icon-active{
    transform: rotate(180deg);
}


li{
    /* text-transform: uppercase; */
    margin-left: .25rem;
    padding: .25rem;
}

</style>