<template>

<footer class="footer">
    <hr>
    <div class="container">
        
         <div class="footer-split">
                <div class="footer-col">
                    <h4>Follow Us</h4>
                    <ul>
                        <li>
                            
                        <a href="https://twitter.com/Alphadof_"><img class="twitter" src="..\assets\twitter.svg" alt="Alphadof twitter page" height="28" ></a>
                        <a href="https://www.instagram.com/alphadof_/"><img class="insta" src="..\assets\insta.svg" alt="Alphadof instagram page" height="28"></a>
                        <a href="https://www.linkedin.com/company/alphadof"><img class="linkedin" src="..\assets\linkedin.svg" alt="Alphadof linkedin page" height="28"></a>
                    
                        </li>
                    </ul>
                </div>
                  
                <div class="footer-col">
                    <h4>Contact Us</h4>
                    
                    <ul>
                        <li><a class="mail-links" href="mailto:contact.us@alphadof.com"><p >contact.us@alphadof.com</p></a></li> 
                    </ul>
                </div>
                <div class="footer-col">
                    <h4>Legal</h4>
                    <ul>
                        <li><router-link class="route-links" to="/terms_conditions" >Terms & Conditions</router-link></li>
                        <li><router-link class="route-links" to="/privacyPolicy" >Privacy Policy</router-link></li>
                    </ul>
                </div>

            </div>
    </div>
           
          
        
        
        <h3 class="footer-main">Alphadof (2024): All Rights Reserved</h3>
         
    </footer>
  
</template>

<script>

  
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}


hr{
 border:  dotted .5rem rgba(255, 255, 255,.5);
 border-bottom: none;
 width: 4%;
 display: block;
margin-right: auto;
margin-left: auto;

}



.container{
    display: flex;
    justify-content: center;
    scroll-behavior: smooth;
    margin-top: 1rem;
    margin-bottom:1rem ;
}

.footer{
    /* background: #EDF5FF; */
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    margin-top: auto;

    
        
    
}
p{
    min-width: 85%;
    max-width: 90%;
    color: rgba(255, 255, 255, 0.8);
}
ul{
    list-style: none;
    font-size: .8rem;
    
}
.footer-col{
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 3rem;
}
.footer-col h4{
    font-size: 1rem;
    color: white;
    text-transform: capitalize;
    margin-bottom: 1rem;
    margin-top:2rem ;
    font-weight: 400;
    
    
}

.footer-col img{
    margin-left:.2rem;
    margin-right:.5rem ;
}

.mail-links{
    font-size: .9rem;
    color: rgba(255, 255, 255, 0.7);
    padding: 0;
    font-weight: 500;
    text-decoration: none;
    max-width: 80%;

}
.footer-split{
    display: flex;
    justify-content: center;
    align-items: center;

}

@media (max-width:52rem) {

    hr{
        border:  dotted .5rem rgba(255, 255, 255,.5);
         border-bottom: none;
        width: 16%;
        display: block;
        margin-right: auto;
        margin-left: auto;

}
    .footer-split{
        display: flex;
        flex-direction: column;
        
    }
    .container{
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-bottom:1rem ;
        /* padding-left: 1.5rem; */
        
        
    }
    .footer-col{
        width: 100%;
        
    }
    
}
.footer-links{
    margin-left:8rem;
}

.route-links{
    display: flex;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 500;
    max-width: 100%;
    justify-content: center;
}




@media (max-width:40em){

    .row{
        display: flex;
        flex-direction:column ;
        flex-wrap: wrap;
    }
    
    
}


.footer-main{
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    padding-top: 2rem;
    font-size: .8rem;
    font-weight: 500;
}

</style>