<template>
    <Header/>
    <body>
        <div class="main-color">
            <section>
        <div class="container">
                <div class="illustration">
                    <img class="firstart" src="..\assets\overview.gif" alt="Animated overview">
                </div>
                <div class="first-part">
                    <h2 class="main-heading" >
                        Moments shared by people all in one place
                    </h2>
                    <h4 class="sub-heading">
                        Contribute your relatable moments in a single space and get a vibe check                    </h4>
                    <div class="email">
                        <vee-form @submit="postData"  action="" method="post" :validation-schema="schema">
                           <vee-field name='email' id="email-field" type="email" placeholder="Email address" required/>
                           <input class="submit-button" type="button" value="Early Access" @click.prevent="postData"  :disabled="reg_in_submission" >
                           <div>
                                <ErrorMessage class="error-message" name='email'/>
                           </div>
                           
                        </vee-form>
                
                    </div>
                    <div class="error-msg" v-if="reg_show_alert">
                        {{reg_alert_msg}}
                    </div>
                    <p class="feedback" v-if="feedback_duration">{{feedback}}</p>
                </div>
            <!-- <div class="cursor" @mouseover="cursorMove"></div> -->
        </div>
            </section>
            
    </div>
    </body>

    <Footer/>
    

</template>

<script>
import Footer from '../components/footer.vue';
import Header from '../components/header.vue';





export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  components:{
      Footer,
      Header
  },
  
  data() {
      return {
          showModal:false,
          feedback:'',
          flag:false,
          flag2:false,
          flag3:false,
          flag4:false,
          reg_in_submission:false,
          reg_show_alert:false,
          feedback_duration:true,
          nopopup:true,
          reg_alert_msg:'please wait! Email sending',

          schema:{
              email:'required|min:3|max:100|email'
          },
          VUE_APP_AWSURL: process.env.VUE_APP_AWSURL.toString()
      }
  },
  methods: {
    // cursorMove(e){
    //     const cursor= document.querySelector('.cursor');
    //     document.addEventListener('mouseover',(e)=>{
    //     cursor.style.left=e.pageX+'px';
    //     cursor.style.top=e.pageY+'px';
    //     })
    // },
    



     async postData(e) {

         
         this.reg_in_submission=true;
         this.reg_show_alert=true;
         this.reg_alert_msg='please wait'
        var email=document.getElementById('email-field').value;
        var url=this.VUE_APP_AWSURL;
    
        const response = await fetch(url, {
        method: 'POST',
        
        headers: {
        'Content-Type': 'application/json'
     
        },
    
        body: JSON.stringify({
            email:email
        })
  }).then(response =>{
      this.reg_alert_msg='email'
      this.reg_in_submission=false;
        this.reg_show_alert=false;
       
      return response.json();
    
  }).then(data =>
      this.feedback=data["body"].toString()

  ).catch(error =>console.log('error'));
    
    },
 },
 
};
 
</script>


<style>

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


html{
    scroll-behavior: smooth;
    margin-top: 0;
    /* height: 100%; */
    background: linear-gradient(180deg, rgba(21,92,220,1) 0%, rgba(35,131,228,1) 50%, rgba(47,179,238,1) 100%);
    
}


.video{
    padding-left: 2rem;
    border-radius: 2rem;
}


section{
    display: block;
    padding-top: .2rem;
}

.first-part{
    padding-top:0;
}
@media (min-width:40rem) {
.first-part{
    padding-top:2rem;
    }

    
}


img{
    max-width: 100%;
    
    /* border-radius: 1rem; */

}
.container{
    margin-inline: auto;
    width: min(90% ,80.5rem);
}




.split{
    display: flex;
    flex-direction: column;
    
}
.split{
    display: flex;
    flex-direction: column;
}


.illustration{
    /* padding-top: .5rem; */
    max-width: 100%;

}

.notifi{
    padding-top:3rem;
}
.notifi:hover{
    transform: scale(1.1);
    transition: transform ease-in-out .2s;
}
p{
    margin: 0;
    
}




@media (min-width:40em){
    .split{
        display: flex;
        flex-direction: row;
    }
    .split > * {
        flex-basis: 100%;
    }
    .split > * + *{
        margin-left: 2em;

    }
   
    .split-column{
        display: flex;
        flex-direction: column;
    }
 
    
}


.main-heading{
 font-size: 2.8rem;
 font-family: 'DM Serif Text',serif;
 font-style: normal;
 font-weight: 400;
 color:white;
 text-align: center;
 margin: 0rem 20rem 0rem 20rem ;
 /* transition: 0.2s; */
}

/* .cursor{
    position: fixed;
    width: .5rem;
    height: .5rem;
    background:#fff;
    border-radius: 50%;
    transition: 0.1s;
    transform: translate(-50%,-50%);
    pointer-events: none;
    mix-blend-mode: difference;
}

.main-heading:hover ~ .cursor{
    transform: scale(2);
} */

.image{
    width: 38.708rem;
    height: 45.063rem;
    margin-top:3rem;
    
}

   

.sub-heading{
    
    
    /* top: 19.75rem; */
    max-width: 95%;
    padding-bottom: 1.5rem;
    padding-top: 1rem;
    margin: 0rem 20rem 0rem 20rem;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    text-align: center;
  /* color: rgba(0, 0, 0, 0.5); */
  color: rgba(255, 255, 255, .6);
}


.email{
    /* display: flex; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}

@media (max-width:80rem){
  
  .main-heading{
      font-size: 2.3rem;
      font-family: 'DM Serif Text',serif;
      margin-left: 10rem;
      margin-right: 10rem;
      padding-top: 0rem;

  }
 
  .sub-heading{
      font-size: 1.2rem;
      margin-left: 10rem;
      margin-right: 10rem;
  }

  .firstart{
    max-width: 100%;
    height: 100%;
    height: 10rem;
    border-radius:1rem;
    box-shadow: 0px 0px 10px 2px #2fb1ee6f;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0rem;
    
}
  
}

@media (max-width:70rem){
  
    .main-heading{
        font-size: 2.2rem;
        font-family: 'DM Serif Text',serif;
        margin-left: 8rem;
        margin-right: 8rem;
        padding-top: 0rem;

    }
   
    .sub-heading{
        font-size: 1rem;
        margin-left: 8rem;
        margin-right: 8rem;
    }
    
    .firstart{
    max-width: 100%;
    height: 100%;
    height: 10rem;
    border-radius:1rem;
    box-shadow: 0px 0px 10px 2px #2fb1ee6f;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0rem;
    
}
}

@media (max-width:50rem){
  
 
  .main-heading{
      font-size: 1.7rem;
      font-family: 'DM Serif Text',serif;
      margin-left: 6rem;
      margin-right: 6rem;
      padding-top: 1rem;
  }
 
  .sub-heading{
      font-size: .9rem;
      margin-left: 6rem;
      margin-right: 6rem;
  }

  .firstart{
    max-width: 100%;
    height: 100%;
    height: 10rem;
    border-radius:1rem;
    box-shadow: 0px 0px 10px 2px #2fb1ee6f;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0rem;
    
}
 
}


@media (max-width:30rem){
    .email{
        display: flex;
        flex-direction:  column;
        justify-content: center;
    }
    .main-heading{
        font-size: 1.7rem;
        font-family: 'DM Serif Text',serif;
        margin-left: 0rem;
        margin-right: 0rem;
        padding-top: 1rem;
    }
    
    
    
    .sub-heading{
        font-size: .9rem;
        margin-left: 0rem;
        margin-right: 0rem;
    }
    
    .image{
        width: 100%;
        height: 100%;
        margin-top:1rem ;
    }

    .firstart{
    max-width: 100%;
    height: 100%;
    height: 10rem;
    border-radius:1rem;
    box-shadow: 0px 0px 10px 2px #2fb1ee6f;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0rem;
    
}
    
   
}

#email-field{
display: flex;

width: 14rem;
height: 2rem;
margin-bottom: .75rem;

padding-left: 1rem;
margin-right: .5rem;
background: #e8f2ff;
border-radius: .6rem;
border-width: .2rem;
outline-color: #D8E9FF;
border: 2px black;
font-family: 'Nunito', sans-serif;
font-style: normal;
font-weight: normal;
font-size: .9rem;
transition: width height .9s;
/* text-align: center; */
/* identical to box height */
color: rgba(5, 4, 10, 0.65);

}




.submit-button{
    /* display: flex; */
    width: 6rem;
    height: 2rem;
    border-width: 0rem;
    font-style: normal;
    font-weight: normal;
    font-family: 'Nunito', sans-serif;
    font-size: .75rem;
    background: #59b60c;
    border-radius: .5rem;
    color: #E6F1FE;
    display: block;
    margin-left: auto;
    margin-right:auto ;
    /* justify-content: center;
    align-self: center; */
}

.submit-button:hover{
    transform:scale(1.1);
    transition: transform ease-in-out .5s;
}

@media (max-width:20rem){
    #email-field{
        width: 12rem;
        height: 1.8rem;
        border-radius: .4rem;
        border-color: #07092C;
    }
    .submit-button{
        height: 1.8rem;
        border-radius: .4rem;
        width:6rem;
        display: block;
        margin-left: auto;
        margin-right:auto ;
    }

}

.sign-ups{
    color:#3FA236;
    font-size: 1.5rem;
    font-weight: 400;
  
}

.follow{
    
    width: 101px;
    height: 19.55px;
    margin-top: 5rem;
    margin-bottom: 0rem;
    font-style: normal;
    font-weight: normal;
    font-size: 15.6387px;
    
    /* identical to box height */
    
    
    color: rgba(0, 0, 0, 0.5);  
}

.links{
    
    margin-top:1rem ;
}


.insta:hover{
    
    
    transform:scale(1.2);
    transition: transform ease-in-out .5s;
}
.twitter:hover{
    
    transform:scale(1.2);
    transition: transform ease-in-out .5s;
}

 .linkedin:hover{
    
    transform:scale(1.2);
    transition: transform ease-in-out .5s;
} 


hr{
    width: 97%;
    color: blueviolet;
}


.firstart{
    height: 100%;
    height: 12rem;
    max-width: 100%;
    border-radius: 1rem;
    box-shadow: 0px 0px 10px 2px #2fb1ee6f;  
    display: block;
    margin-left: auto;
    margin-right: auto;  
    /* padding-left: 1rem; */
    margin-top: 1rem;
    margin-bottom: 1rem;
}
@media (max-width:40rem) {
   .firstart{
    max-width: 100%;
    height: 100%;
    height: 10rem;
    border-radius:1rem;
    box-shadow: 0px 0px 10px 2px #2fb1ee6f;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0rem;
    
}




}


#Rectangle-84:hover{
    transform:scale(1.1);
    transition: transform ease-in-out .5s;
}

.feedback{
    padding-top: .5rem;
    font-size: .8rem;
    color: rgb(46, 21, 74);
    /* padding-left: 1rem; */
   display: flex;
   justify-content: center;
}

.error-message{
    font-size: .8rem;
    color: rgb(46, 21, 74);
    /* padding-left: 1rem; */
   display: flex;
   justify-content: center;
    
}

.error-msg{
    font-size: .8rem;
    color: rgb(46, 21, 74);
    /* padding-left: 1rem; */
   display: flex;
   justify-content: center;
}




</style>
