<template>
  <div class="home">
    <myPage/>
  </div>
</template>

<script>
// @ is an alias to /src
import MyPage from '../components/MyPage.vue';
import About from './About.vue';

export default {
  name: 'Home',
  components: {
    MyPage,
    About
   
  },
  data() {
    return {
      // eslint-disable-next-line comma-dangle
    };
  },
};
</script>
