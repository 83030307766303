import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VeeValidatePlugin from './includes/validation';
import VueGtag from "vue-gtag";

const VUE_APP_ID=process.env.VUE_APP_ID
const app=createApp(App)
app.use(store);
app.use(router);
app.use(VeeValidatePlugin);
app.use(VueGtag, {
    config: { id:VUE_APP_ID, params: {
        anonymize_ip: true
      }}
  },router);
app.mount('#app');