<template>

  <router-view/>
</template>

<style>
#app {
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* font-family: Source Sans Pro; */
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  margin-left: 0;
  margin-right: 0;
  margin-bottom:0;
  scroll-behavior: smooth;

}


</style>
