import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import terms_conditions from '@/components/terms&conditions';
import privacyPolicy from '@/components/privacyPolicy';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/terms_conditions',
    name: 'terms&conditions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:terms_conditions,
  },
  {
    path: '/privacyPolicy',
    name: 'privacy policy',
    component: privacyPolicy,
  },
  // {
  //   path: '/blog',
  //   name: 'blog',
  //   component: Blog,
  // },

  // {
  //   path: '/blog/:title',
  //   name: 'blogPost',
  //   component: PostView,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
